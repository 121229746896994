import { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { CaseEventModel } from './CaseEventFeedScreen'


interface ViewCaseEventDrawerProps {
  caseEvent: CaseEventModel | null;
  isOpen: boolean;
  onClose(): void;
};

export const ViewCaseEventDrawer: FC<ViewCaseEventDrawerProps> = props => {
  const { caseEvent, isOpen, onClose } = props;

  return (
    <Drawer
      width="lg"
      className="_ViewCaseEventDrawer"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="flex flex-col min-h-screen">
        <DrawerHeader
          icon="info-circle"
          title="View Case Event"
          onClose={onClose}
        />
        <h3 className="px-4 py-6 font-semibold text-gray-700">Payload</h3>
        <pre className="flex-grow p-3 overflow-x-auto text-xs text-green-500 bg-gray-800 shadow-inner">
          {
            caseEvent ? (
              JSON.stringify(caseEvent.payload, null, 2)
            ) : null
          }
        </pre>
      </div>
    </Drawer>
  );
};
