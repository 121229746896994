import { FC, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { Spinner } from "components/Spinner";
import { Button } from "components/Button";
import { FilterPanel, FilterModel, defaultFilter } from "./FilterPanel";
import { ScreenTitle } from "context/ScreenTitle";
import { Container } from "components/Container";
import { NoResults } from "components/NoResults";
import { distanceInWords } from "utils/dateFormatters";
import { TD, TH, Table, TableContainer } from "components/Table";
import { makeAppendItems } from "lib/makeAppendItems";
import { ViewCaseEventDrawer } from "./ViewCaseEventDrawer";

const PAGE_SIZE = 100

const LIST_CASE_EVENTS = gql`
  query ListCaseEvents($first: Int, $after: UUID4, $filter: ListCaseEventsFilter) {
    caseEvents(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        caseNumber
        eventName
        payload
        insertedAt
        insertedAtString(timeZone: "US/Eastern")
      }
    }
  }
`;

interface Data {
  caseEvents: Paginated<CaseEventModel>
}

export interface CaseEventModel {
  id: string;
  caseNumber: string;
  eventName: string;
  payload: any;
  insertedAt: string;
  insertedAtString: string;
}

interface Variables {
  first: number;
  filter: FilterModel;
}

interface CaseEventFeedScreenProps { };

export const CaseEventFeedScreen: FC<CaseEventFeedScreenProps> = props => {
  const [filter, setFilter] = useState<FilterModel>(defaultFilter);

  const [selectedEvent, setSelectedEvent] = useState<CaseEventModel | null>(null);

  const { data, loading, error, refetch, fetchMore } = useQuery<Data, Variables>(LIST_CASE_EVENTS, {
    variables: {
      first: PAGE_SIZE,
      filter
    }
  });

  return (
    <>
      <ViewCaseEventDrawer
        caseEvent={selectedEvent}
        isOpen={!!selectedEvent}
        onClose={() => setSelectedEvent(null)}
      />
      <Container className="_CaseEventFeedScreen">
        <ScreenTitle title="Case Event Feed" />

        <FilterPanel value={filter} onChange={setFilter} />

        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.caseEvents ? (
          <div className="p-8 text-center">
            <p className="py-4 text-gray-700">Failed to load</p>
            <Button type="button" size="sm" onClick={() => refetch()}>Retry</Button>
          </div>
        ) : data.caseEvents.items.length === 0 ? (
          <NoResults icon="search" text="No matching case events" />
        ) : (
          <div className="mt-2">
            <p className="py-2 font-semibold text-center text-indigo-700">Events are shown in <span className="font-bold text-indigo-900 underline">Descending</span> order (most recent first)</p>
            <TableContainer>
              <Table className="text-xs text-left">
                <thead>
                  <tr>
                    <TH>Case Number</TH>
                    <TH>Event Name</TH>
                    <TH>Received At</TH>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.caseEvents.items.map((ce) => (
                      <tr key={ce.id} className="hover:bg-blue-50 bg-white cursor-pointer" onClick={() => setSelectedEvent(ce)}>
                        <TD style={{ padding: "0.5rem 2rem" }} className="font-mono font-semibold text-gray-800">{ce.caseNumber}</TD>
                        <TD style={{ padding: "0.5rem 2rem" }} className="font-mono text-purple-800">{ce.eventName}</TD>
                        <TD style={{ padding: "0.5rem 2rem" }} className="font-mono">{ce.insertedAtString} <span className="ml-2 text-xs font-light text-gray-500">({distanceInWords(ce.insertedAt)} ago)</span></TD>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              {
                data.caseEvents.items.length > 0 ? (
                  <div className="p-2 text-center text-gray-500 bg-white border-t border-gray-300">
                    {data.caseEvents.endOfList ? (
                      <p>End of list</p>
                    ) : (
                      <Button
                        type="button"
                        size="sm"
                        kind="secondary"
                        color="blue"
                        className="w-full"
                        onClick={() => {
                          fetchMore({
                            query: LIST_CASE_EVENTS,
                            variables: {
                              first: PAGE_SIZE,
                              after: data.caseEvents.cursor,
                              filter: filter,
                            },
                            updateQuery,
                          });
                        }}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                ) : null}
            </TableContainer>
          </div>
        )}
      </Container>
    </>
  );
};

const updateQuery = makeAppendItems<Data>("caseEvents");
